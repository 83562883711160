import $ from './app/jquery-shim.js';
import Utils from './app/utils.js';
import FixedHeader from './app/fixedheader.js';
import Slidebar from './app/slidebar.js';
import Accordion from './app/accordion.js';
import HeightLine from './app/heightline.js';
import myScript from './scripts.js';
import OwlCarousel from 'owl.carousel';
import imagesLoaded from 'imagesloaded';
import fontAwesome from "font-awesome/scss/font-awesome.scss";
import OwlCss from "owl.carousel/dist/assets/owl.carousel.css";
import OwlThemeCss from "owl.carousel/dist/assets/owl.theme.default.css";


class App {
  constructor() {
    this.Utils = new Utils();
    this.FixedHeader = new FixedHeader();
    this.Slidebar = new Slidebar();
    this.Accordion = new Accordion();
    this.HeightLine = new HeightLine();


    //SPメニューの高さ取得
    function menuHight() {
      var win = $(window).innerWidth();
      if (win > 750) {
        return false;
      }

      var $smpHeaderHeight = $('.l-header').height();
      var windowHeight = window.innerHeight;
      var winH = windowHeight - $smpHeaderHeight;

      //動かすターゲットを取得
      var targetSlidebarWrap = $('.c-slidebar-menu'),
        targetSlidebarMenu = $('.c-slidebar__parent'),
        targetSlidebarBtn = $('.c-slidebar-menu__parent');


      //いざ実行(クリックしたら処理を実行する)
      targetSlidebarBtn.on('click', function () {
        $('.c-slidebar-menu').toggleClass('is-active');

      });
    }

    //owlcarousel
    function owlCarousel() {
      var owls = $('.owl-carousel');

      if (owls.length === 0) {
        return false
      }

      //->カード_カルーセル
      owls.imagesLoaded(function () {
        let owlnavImage;
        let $targets = $('.js-card-slider');
        $targets.each(function(i, elm) {
          if ($(elm).hasClass('is-arrow-white')){
            owlnavImage =['<img src="../assets/images/icon-slider-prev-white.png" />', '<img src="../assets/images/icon-slider-next-white.png" />'];
          } else {
            owlnavImage = ['<img src="../assets/images/icon-slider-prev.png" />', '<img src="../assets/images/icon-slider-next.png" />'];
          }
          if ($(elm).children().length < 4 || $(elm).hasClass('is-sm-active') && window.innerWidth > 750){
            $(elm).toggleClass('owl-carousel');
          } else {
            $(elm).owlCarousel({
              margin: 36,
              dots: true,
              loop: true,
              nav: true,
              autoplayHoverPause: true,
              autoplay: true,
              autoplaySpeed: 500,
              autoWidth: false,
              autoHeight: false,
              center: true,
              navText: owlnavImage,
              responsive: {
                // breakpoint from 0 up
                0: {
                  items: 1,
                  margin: 16,
                  stagePadding: 32,
                },
                // breakpoint from 750 up
                950: {
                  items: 3,
                }
              }
            });
            var maxHeight = 0;
            $(elm).find('.owl-item').children().each(function(idx, elem) {
              var height = $(elem).height();
              if(maxHeight < height) {
                maxHeight = height;
              }
            });
            $(elm).find('.owl-item').children().height(maxHeight);
          }
        });
      });
    }


    function animationReady() {
      let $target = $('.c-main-visual');
      $(window).on('load', function () {
        $target.addClass('is-animating');
      });
    }

    $(function () {
      menuHight();
      owlCarousel();
      animationReady();
    });
  }
}

window.GApp = new App();
